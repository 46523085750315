<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

/**
 * Form Validation component
 */
export default {
  page: {
    title: "Buy Package",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Form Buy Package",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "User",
          href: "/user"
        },
        {
          text: "Buy Package",
          active: true
        }
      ],
      form: {
        package: null,
        discount: null,
        voucherCode: null,
        paymentMethod: null,
        location: null
      },
      voucherCode: null,
      voucher: null,
      isVoucherInValid: false,
      user: null,
      userPackages: [],
      detailPackage: null,
      isError: false,
      errorMessage: null,
      isPackageNotSelected: false,
      packages: [],
      showDetailPackage: false,
      previewPackage: {},
      optionsPaymentMethod: ['cash', 'debit', 'credit card', 'transfer', 'OVO', 'go pay', 'shopee pay', 'blibli in store', 'kredivo', 'voucher', 'yobo'],
      isPaymentMethodNotSelected: false,
      historyTreatment: [],
      locations: [],
      islocationsNotSelected: false,
      packageIdToRefund: null,
      refundReason: null,
      isRefundReasonNotFilled: false,
      packageDetail: {
        isVoid: false
      },
      voidReason: null,
      isVoidReasonNotFilled: true
    };
  },
  created() {
    this.fetchPackage()
    this.fetchLocation()
  },
  watch: {
    voucherCode(newVal) {
      if (newVal == '' || newVal === null) {
        this.isVoucherInValid = false
        this.voucher = null
      }
    }
  },
  computed: {
    discountLabel() {
      return this.form.discount * this.packagePrice / 100
    },
    discountVoucher() {
      if (this.voucher) {
        if (this.voucher.type == 'fixed') {
          return this.voucher.amount
        } else {
          return (this.packagePrice - this.discountLabel) * this.voucher.amount / 100;
        }
      } else {
        return 0
      }
    },
    packagePrice() {
      if (this.form.package) {
        return this.form.package.price
      } else {
        return 0
      }
    },
    total() {
      const total = this.packagePrice - this.discountLabel - this.discountVoucher
      if (total > 0) {
        return this.convertToRupiah(total, false)
      } else {
        return 0
      }
    }
  },
  mounted() {
    this.fetchUser()
    this.fetchUserPackage()
  },
  methods: {
    fetchPackage() {
      this.$axios.get(`package`, {
          params: {
            page: 1,
            limit: 1000,
            sortBy: 'name',
            sortType: -1
          }
        }).then(response => {
          this.packages = response.data.result.package
        })
    },
    findPackage(selectedPackage) {
      this.form.package = selectedPackage

      this.$axios.get(`package/${this.form.package.packageId}`)
        .then( async (response) => {
          console.log(response)
          let res = response.data.result
          this.previewPackage = {
            name: res.package.name,
            sku: res.package.sku,
            price: res.package.price, 
            type: (res.package.type !== null)? res.package.type.name : null,
            description: res.package.description, 
            expiredIn: res.package.expiredIn,
            treatments: res.package.treatments,
            locations: res.package.locations.map( item => item.name)
          }
          this.showDetailPackage = true
        })
    },
    getHistoryTreatment(id) {
      this.$axios.get(`user-package/detail/${id}`)
        .then( async (response) => {
          this.historyTreatment = response.data.result.historyTreatment
        })
    },
    onSubmit() {
      this.isPackageNotSelected = (this.form.package === null)? true : false;
      this.isPaymentMethodNotSelected = (this.form.paymentMethod === null)? true : false;
      this.islocationsNotSelected = this.form.location === null ? true : false;

      if (this.isPackageNotSelected || this.isPaymentMethodNotSelected || this.islocationsNotSelected) {
        return false
      }

      let param = new FormData()
      param.append('user', this.$route.params.id)
      param.append('package', this.form.package.packageId)
      param.append("location", this.form.location.locationId);
      param.append('discount', this.form.discount)
      param.append('paymentMethod', this.form.paymentMethod)
      param.append('total', this.form.package.price)
      if (this.voucher) {
        param.append("voucher", this.voucher._id)
      }

      this.$axios.post(`/user-package/buy`, param)
      .then(response => {
        console.log(response)
        this.resetForm()
        this.fetchUserPackage()
        Swal.fire("Success", "Package added Successfully", "success");
      })
    },
    fetchUser() {
      this.$axios.get(`/user/${this.$route.params.id}`)
        .then( (response) => { 
          this.user = response.data.result.user
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
    },
    fetchUserPackage() {
      this.$axios.get(`/user-package/history/${this.$route.params.id}`)
        .then( (response) => { 
          this.userPackages = response.data.result.packages
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
    },
    goToAppointment(appointment) {
      if (appointment.checkout === false) {
        window.open(`/appointment/edit/${appointment._id}`, '_blank')
      } else {
        window.open(`/appointment?show=${appointment._id}`, '_blank')
      }
    },
    openModal(userPackage) {
      this.getHistoryTreatment(userPackage._id)
      this.detailPackage = userPackage
      this.$bvModal.show('modal-detail-package')
    },
    closeModal() {
      this.detailPackage = null
      this.historyTreatment = []
      this.$bvModal.hide('modal-detail-package')
    },
    resetForm() {
      this.form = {
        package: null,
        discount: null,
        paymentMethod: null
      }
      this.showDetailPackage = false
      this.voucherCode = null
      this.voucher = null
      this.isVoucherInValid = false
      this.previewPackage = {}
    },
    print(id) {
      window.open(`${process.env.VUE_APP_BASE_URL_API}/user-package/print/${id}`, '_blank')
    },
    redeemVoucher() {
      let param = new FormData()
      param.append("voucher", this.voucherCode);

      this.$axios
        .post(`voucher/redeem/package`, param)
        .then((response) => {
          console.log(response)
          this.isVoucherInValid = false
          this.voucher = response.data.result.voucher
        })
        .catch((error) => {
          console.log(error)
          this.isVoucherInValid = true
          this.voucher = null
        });
    },
    fetchLocation() {
      this.$axios
        .get(`location`)
        .then((response) => {
          let result = response.data.result.location;
          this.locations = result;
        });
    },
     modalRefund(userPackageId) {
      this.packageIdToRefund = userPackageId
      this.$bvModal.show('modal-refund') 
    },
    confirmRefund(event) {
      event.preventDefault()
      if (this.refundReason === null || this.refundReason == "") {
        this.isRefundReasonNotFilled = true;
      } else {
        this.isRefundReasonNotFilled = false;
        let param = new FormData();
        param.append('reason', this.refundReason)
        this.$axios.put('/user-package/refund/' + this.packageIdToRefund, param)
          .then(response => {
            console.log('res',response)
            this.cancelRefund()  
            Swal.fire("Success", "Package Refunded Success", "success");
            this.fetchUserPackage(); 
          })
      }
    },
    cancelRefund() {
      this.packageIdToRefund = null
      this.refundReason = null  
      this.$bvModal.hide('modal-refund') 
    },
    modalVoid(userPackage) {
      this.packageDetail = userPackage;
      this.voidReason = userPackage.voidReason;
      this.isVoidReasonNotFilled = false;
      this.$bvModal.show('modal-void');
    },
    confirmVoid(event) {
      event.preventDefault();
      if (!this.packageDetail) return;
      if (!this.packageDetail.isVoid && (this.voidReason === null || this.voidReason == "")) {
        this.isVoidReasonNotFilled = true;
      } else {
        this.isVoidReasonNotFilled = false;
        let param = new FormData();
        param.append('reason', this.voidReason);
        this.$axios.put('/user-package/void/' + this.packageDetail._id, param)
          .then(() => {
            this.cancelVoid();
            Swal.fire("Success", "User package successfully updated", "success");
            this.fetchUserPackage(); 
          });
      }
    },
    cancelVoid() {
      this.packageDetail = { isVoid: false };
      this.voidReason = null;
      this.$bvModal.hide('modal-void');
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
            >{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">
              
              <div class="form-group">
                <label>Package</label>
                <multiselect
                  :class="{ 'is-invalid': isPackageNotSelected }"
                  v-model="form.package"
                  label="name"
                  track-by="packageId"
                  placeholder="Select One"
                  open-direction="bottom"
                  :options="packages"
                  :multiple="false"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :hide-selected="false"
                  :allowEmpty="false"
                  @select="findPackage"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.name }}</strong>
                  </template>
                </multiselect>
                <div v-if="isPackageNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group" v-if="showDetailPackage">
                <label>Preview Detail Package</label>
                <div class="row" style="border: 1px solid; border-radius: 5px">
                  <div class="col-lg-6">
                    <table class="table">
                      <tr>
                        <th>Name</th>
                        <td>: {{ previewPackage.name }}</td>
                      </tr>
                      <tr>
                        <th>SKU</th>
                        <td>: {{ previewPackage.sku }}</td>
                      </tr>
                      <tr>
                        <th>Price</th>
                        <td>: {{ previewPackage.price | convertToRupiah}}</td>
                      </tr>
                      <tr>
                        <th>Expire In</th>
                        <td>: {{ previewPackage.expiredIn }} after purchased</td>
                      </tr>
                      <!-- <tr>
                        <th>Type</th>
                        <td>: {{ previewPackage.type }}</td>
                      </tr> -->
                      <tr>
                        <th>Description</th>
                        <td>: {{ previewPackage.description }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-lg-6">
                    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3">
                      <b-tab>
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                            <i class="fas fa-home"></i>
                          </span>
                          <span class="d-none d-sm-inline-block">Treatments</span>
                        </template>
                        <ul class="list-group list-group-flush overflow-auto" style="max-height: 200px">
                          <li class="list-group-item" v-for="(treatment, index) in previewPackage.treatments" :key="index">
                            {{ treatment.treatment.name | capitalize }}
                            <span class="float-right">Quantity : {{ treatment.quantity }}</span>
                          </li>
                        </ul>
                      </b-tab> 
                      <b-tab>
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                            <i class="far fa-envelope"></i>
                          </span>
                          <span class="d-none d-sm-inline-block">Locations</span>
                        </template>
                        <ul class="list-group list-group-flush overflow-auto" style="max-height: 200px">
                          <li class="list-group-item" v-for="(location, index) in previewPackage.locations" :key="index">{{ location | capitalize }}</li>
                        </ul>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>Location</label>
                <multiselect
                  :class="{ 'is-invalid': islocationsNotSelected }"
                  v-model="form.location"
                  label="name"
                  track-by="locationId"
                  placeholder="Select One"
                  open-direction="bottom"
                  :maxHeight="500"
                  :options="locations"
                  :multiple="false"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :hide-selected="false"
                  :allowEmpty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.name }}</strong>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                <div v-if="islocationsNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <!-- <template v-if="$store.getters.checkPermission('SET_DISCOUNT_CHECKOUT_PACKAGE_USER')">
                <div class="form-group" v-if="showDetailPackage">
                  <label>Discount</label>
                  <b-input-group append="%">
                    <input
                      v-model="form.discount"
                      type="number"
                      min="0"
                      step="0.5"
                      class="form-control"
                      name="discount"
                    />
                  </b-input-group>
                </div>
              </template> -->

              <div class="form-group" v-if="showDetailPackage">
                <label>Voucher</label>
                <b-input-group :class="{ 'is-invalid': isVoucherInValid }">
                  <input
                    v-model="voucherCode"
                    type="text"
                    class="form-control"
                    name="voucher"
                    placeholder="input voucher code here"
                    :class="{ 'is-invalid': isVoucherInValid }"
                    @keyup.enter="redeemVoucher"
                  />
                
                  <b-input-group-append>
                    <b-button variant="outline-primary" @click="redeemVoucher">Redeem</b-button>
                  </b-input-group-append>
                </b-input-group>
                <div v-if="isVoucherInValid" class="invalid-feedback">
                  <span>Voucher Not Valid</span>
                </div>
              </div>

              <div class="form-group" v-if="showDetailPackage">
                <label>Total Payment</label>
                <b-input-group prepend="Rp.">
                  <input
                    v-model="total"
                    type="text"
                    class="form-control"
                    name="price"
                    disabled
                  />
                </b-input-group>
              </div>

              <div class="form-group">
                <label>Payment Method</label> 
                <multiselect :class="{ 'is-invalid': isPaymentMethodNotSelected }" v-model="form.paymentMethod" deselect-label="Can't remove this value" placeholder="Select one" :options="optionsPaymentMethod" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                </multiselect>
                <div v-if="isPaymentMethodNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>


              <button type="submit" class="btn btn-primary mt-2 float-right">Buy This Package</button>              
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card" v-if="userPackages.length">
          <div class="card-body">
            <h5>{{ user.firstname | capitalize }} history package</h5>
            <div class="table-responsive mb-0">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="width: 5%">No</th>
                    <th style="width: 10%">Order</th>
                    <th style="width: 20%">Package</th>
                    <th style="width: 10%">Total Payment</th>
                    <th style="width: 10%">Payment Method</th>
                    <th style="width: 10%">Status</th>
                    <th style="width: 15%">Purchase Date</th>
                    <th style="width: 15%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(pack, index) in userPackages" :key="index">
                    <th scope="row">{{ index+1 }}</th>
                    <td>
                      {{ pack.booking_code }}
                    </td>
                    <td>
                      {{ pack.package.name }}
                    </td>
                    <td>
                      {{ pack.total | convertToRupiah }}
                    </td>
                    <td>
                      {{ pack.paymentMethod | capitalize }}
                    </td>
                    <td>
                      <template v-if="pack.isRefunded">
                        <b-button variant="warning" size="sm">Refunded</b-button>
                      </template>
                      <template v-else>
                        <template v-if="pack.isAvailable">  
                          <b-button variant="primary" size="sm">Available</b-button>
                        </template>
                        <template v-else>  
                          <b-button variant="secondary" size="sm">Not Available</b-button>
                        </template>
                      </template>
                    </td>
                    <td>{{ pack.createdAt | date_time }}</td>
                    <td>
                      <b-button class="m-1" variant="outline-info" v-b-tooltip.hover title="View Detail" size="sm" @click="openModal(pack)">Detail</b-button> &nbsp;
                      <b-button class="m-1" v-if="pack.canRefund" variant="outline-warning" v-b-tooltip.hover title="Refund Package" size="sm" @click="modalRefund(pack._id)">Refund</b-button> &nbsp;
                      <b-button class="m-1" variant="outline-primary" v-b-tooltip.hover title="Print Receipt Package" size="sm" @click="print(pack._id)">Print</b-button> &nbsp;
                      <b-button :variant="pack.isVoid ? 'danger' : 'outline-danger'"  v-b-tooltip.hover :title="pack.isVoid ? 'Unvoid' : 'Void'" size="sm" @click="modalVoid(pack)">{{ pack.isVoid ? 'Voided' : 'Set To Void' }}</b-button> 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-detail-package"
      title="Detail Package"
      size="xl"
      title-class="text-black font-18"
      hide-footer
      @close="closeModal"
      no-close-on-backdrop
    >
      <div class="row" v-if="detailPackage !== null">
        <div class="col-6">
          <table class="table">
            <tr>
              <th>Order</th>
              <td>: {{ detailPackage.booking_code }}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>: {{ detailPackage.package.name }}</td>
            </tr>
            <tr>
              <th>SKU</th>
              <td>: {{ detailPackage.package.sku }}</td>
            </tr>
            <tr>
              <th>Price</th>
              <td>: {{ detailPackage.package.price | convertToRupiah}}</td>
            </tr>
            <!-- <tr>
              <th>Type</th>
              <td>: {{ (detailPackage.type !== null)? detailPackage.type.name : null }}</td>
            </tr> -->
            <tr>
              <th>Begin Date</th>
              <td>: {{ detailPackage.beginAt | date_only }}</td>
            </tr>
            <tr>
              <th>Expire Date</th>
              <td>: {{ detailPackage.expiredAt | date_only }}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>: {{ detailPackage.package.description }}</td>
            </tr>
            <tr>
              <th>Purchase Location</th>
              <td>: {{ (detailPackage.purchaseLocation)? detailPackage.purchaseLocation.name : '' }}</td>
            </tr>
            <tr v-if="detailPackage.refundReason !== null">
              <th>Package refunded with reason</th>
              <td>: {{ detailPackage.refundReason }}</td>
            </tr>
          </table>
        </div>
        <div class="col-6">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3">
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">Treatments</span>
              </template>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 5%">No</th>
                    <th style="width: 85%">Treatment</th>
                    <th style="width: 10%">Quantity</th>
                    <th style="width: 10%">Used</th>
                    <th style="width: 10%">Credit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(history, index) in historyTreatment" :key="index">
                    <th scope="row">{{ index+1 }}</th>
                    <td>
                      {{ history.treatment }}
                    </td>
                    <td class="text-center font-weight-bold">
                      {{ history.quantity }}
                    </td>
                    <td class="text-center font-weight-bold">
                      {{ history.used }}
                    </td>
                    <td class="text-center font-weight-bold">
                      {{ history.quantity - history.used }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab> 
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">Locations</span>
              </template>
              <ul class="list-group list-group-flush overflow-auto" style="max-height: 200px">
                <li class="list-group-item" v-for="(location, index) in detailPackage.locations" :key="index">{{ location.name | capitalize }}</li>
              </ul>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-refund"
      centered
      title="Set Appointment to Refunded"
      title-class="font-18"
      @cancel="cancelRefund"
      @ok="confirmRefund"
      >
      <label>Why this package set to be refunded ?</label> 
      <textarea
        v-model="refundReason"
        class="form-control"
        placeholder="input your reason here"
        name="reason"
        :class="{ 'is-invalid': isRefundReasonNotFilled}"
      ></textarea>
      <div v-if="isRefundReasonNotFilled" class="invalid-feedback">
        <span>This value is required.</span>
      </div>
    </b-modal>
    <b-modal
      id="modal-void"
      centered
      :title="`${packageDetail.isVoid ? 'Unvoid' : 'Void'} Package`"
      title-class="font-18"
      @cancel="cancelVoid"
      @ok="confirmVoid"
      >
      <label>Why this package set to be {{`${packageDetail.isVoid ? 'Unvoided' : 'Voided'}`}} ?</label> 
      <textarea
        v-model="voidReason"
        class="form-control"
        placeholder="input your reason here"
        name="reason"
        :class="{ 'is-invalid': isVoidReasonNotFilled}"
        :disabled="packageDetail.isVoid"
      ></textarea>
      <div v-if="isVoidReasonNotFilled" class="invalid-feedback">
        <span>This value is required.</span>
      </div>
    </b-modal>
  </Layout>
</template>